import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ICustomer from '../interfaces/ICustomer';
import Firebase from '../utils/Firebase';
import CustomSpinner from '../utils/components/Spinner';

const Landing = () => {
    const [customers, loadCustomers] = useState<ICustomerState>({ status: 0, data: [] });
    const history = useHistory();
    const fireApp = Firebase();
    const fireStore = fireApp.firestore();

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        fireStore.collection('customers').where('expiry', '>', new Date()).get().then(data => {
            if (data.empty) {
                loadCustomers({ status: 1, data: [] });
            } else {
                const docs = data.docs.map(doc => {
                    return doc.data() as ICustomer;
                });
                loadCustomers({ status: 1, data: docs });
            }
        });
    };

    const triggerLoadCustomer = (customer: ICustomer) => {
        history.push(`/view/${customer.id}`, { customer });
    };

    switch (customers.status) {
        case 1: {
            return (
                <React.Fragment>
                    {
                        customers.data.map((customer, i) => {
                            return (
                                <Card key={`${customer.id}`}>
                                    <Card.Img variant="top" src={customer.logo} />
                                    <Card.Header>{customer.name}</Card.Header>
                                    <Card.Footer>
                                        <Button onClick={() => { triggerLoadCustomer(customer); }}>View Photos</Button>
                                    </Card.Footer>
                                </Card>
                            );
                        })
                    }
                </React.Fragment>
            );
        }
        default: {
            return <CustomSpinner />;
        }
    }
};

export default withRouter(Landing);

interface ICustomerState {
    status: 0 | 1 | 2,
    data: ICustomer[];
}
