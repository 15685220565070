import { Metric } from 'web-vitals';
import {getAnalytics} from './utils/Firebase';

const reportWebVitals = () => {
  const analytics = getAnalytics();
  const onPerfEntry = (name:string,result:Metric) => {
    analytics.logEvent(name,{global:true,...result});
  }
  import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
    getCLS((metric: Metric)=>onPerfEntry("react-wv-CLS",metric));
    getFID((metric: Metric)=>onPerfEntry("react-wv-FID",metric));
    getFCP((metric: Metric)=>onPerfEntry("react-wv-FCP",metric));
    getLCP((metric: Metric)=>onPerfEntry("react-wv-LCP",metric));
    getTTFB((metric: Metric)=>onPerfEntry("react-wv-TTFB",metric));
  });
};

export default reportWebVitals;
