import firebase from 'firebase/app';
import { auth as firebaseUi } from 'firebaseui';
import IAuthUiUser from '../interfaces/IAuthUiUser';

let AuthUiInstance: firebaseUi.AuthUI | null = null;
export const reauthUser = (auth: firebase.auth.Auth, user: IAuthUiUser) => {
    auth.onIdTokenChanged(function (user) {
        if (user) {
            // User is signed in or token was refreshed.
            console.log('Re auth worked', user);
        }
    });
    //@ts-ignore
    // auth.(user.user);
    console.log(auth.currentUser);
};
const AuthUi = (auth: firebase.auth.Auth, params: IParams) => {
    if (!AuthUiInstance) {
        AuthUiInstance = new firebaseUi.AuthUI(auth);
    }
    auth.onIdTokenChanged(function (user) {
        if (user) {
            // User is signed in or token was refreshed.
            console.log(user);
        }
    });
    AuthUiInstance.start('#firebaseui-auth-container', {
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
            signInSuccessWithAuthResult: (authResult: IAuthUiUser, redirectUrl) => {
                // User successfully signed in.
                // Return type determines whether we continue the redirect automatically
                // or whether we leave that to developer to handle.
                return params.authResult(authResult);
            },
            uiShown: params.uiShownFunction
        },
        // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
        signInFlow: 'popup',
        // Terms of service url.
        tosUrl: '<your-tos-url>',
        // Privacy policy url.
        privacyPolicyUrl: '<your-privacy-policy-url>'
    });
};

export default AuthUi;

interface IParams {
    authResult: ((result: IAuthUiUser) => boolean);
    uiShownFunction?: (() => void);
}