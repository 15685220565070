import React, { useEffect, useState } from 'react';
import { Firestore, Reference, StorageError } from '../../utils/Firebase';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Alert from 'react-bootstrap/Alert';

export default function ImageUpload(params: { file: File, firestore: Firestore, storageRef: Reference, customerId: string, projectId: string; refString: string; idPrefix: string; }) {

    const [name, setName] = useState("File");
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState<null | StorageError>(null);

    function imageSize(url: string): Promise<{ width: number, height: number; }> {
        const img = document.createElement("img");

        const promise = new Promise<{ width: number, height: number; }>((resolve, reject) => {
            img.onload = () => {
                // Natural size is the actual image size regardless of rendering.
                // The 'normal' `width`/`height` are for the **rendered** size.
                const width = img.naturalWidth;
                const height = img.naturalHeight;

                // Resolve promise with the width and height
                resolve({ width, height });
            };

            // Reject promise on error
            img.onerror = reject;
        });

        // Setting the source makes it start downloading and eventually call `onload`
        img.src = url;

        return promise;
    }

    useEffect(() => {
        const firebaseLink = params.firestore.collection('images').doc();
        const fileName = `${params.idPrefix}_${firebaseLink.id}_${params.file.name}`;
        const fileId = `${params.idPrefix}_${firebaseLink.id}`;

        new Promise(async (resolve, reject) => {
            const imageDimensions = await imageSize(window.URL.createObjectURL(params.file));

            const commitObject = {
                name: fileName,
                fullSize: `${params.refString}}/${fileName}`,
                id: firebaseLink.id,
                customer: params.customerId,
                project: params.projectId,
                uploadDate: Date.now(),
                fileMeta: {
                    name: params.file.name,
                    size: params.file.size,
                    type: params.file.type,
                    width: imageDimensions.width,
                    height: imageDimensions.height
                }
            };
            setName(fileName);

            const uploadTask = params.storageRef.child(fileName).put(params.file, {
                customMetadata: {
                    fileId,
                    width: imageDimensions.width.toString(),
                    height: imageDimensions.height.toString()
                }
            });
            uploadTask.on(
                "state_changed",
                snapshot => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setProgress(progress);
                },
                error => {
                    // error function ....
                    console.log(error);
                    setError(error);
                },
                () => {
                    setProgress(100);
                    firebaseLink.set(commitObject);
                }
            );

        });
    }, []);


    if (progress === 100) {
        return (
            <div className='row'>
                <div className='col-3'>{params.file.name}</div>
                <div className='col-9'><ProgressBar animated now={progress} label={`${progress}%`} /></div>
            </div>);
    } else if (error) {
        return <Alert variant='danger'>Upload of {name} failed with message: {error}</Alert>;
    } else {
        return (
            <div className='row'>
                <div className='col-3'>{params.file.name}</div>
                <div className='col-9'><ProgressBar now={progress} label={`${progress}%`} variant="success" />;</div>
            </div>);
    }



}