import React from 'react';
import loadingGif from '../../assets/images/loading.gif';

const CustomSpinner = (props:{size?: string}) => {
    let style= {
        maxWidth:"70%",
        maxHeight:"50%"
    };
    switch (props.size) {
        case 'small': style.maxHeight = "30%";
            break;
    }
    return (
        <img src={loadingGif} style={ style } alt="loading..."/>
    );
};

export default CustomSpinner;