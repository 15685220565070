
import firebase from 'firebase/app';

// These imports load individual services into the firebase namespace.
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/performance';
import 'firebase/firestore';
import 'firebase/analytics';

let firebaseApp:firebase.app.App | null = null;
let analytics:firebase.analytics.Analytics | null = null;
let performance: firebase.performance.Performance | null = null;
const Firebase = () => {
    if (firebaseApp) {
        return firebaseApp;
    } else {
        firebaseApp = firebase.initializeApp({
            apiKey: "AIzaSyAEQQd_-5LAZNC88U3pQA-tS064PhML_Uo",
            authDomain: "racephotos-wedgedev.firebaseapp.com",
            databaseURL: "https://racephotos-wedgedev-default-rtdb.europe-west1.firebasedatabase.app",
            projectId: "racephotos-wedgedev",
            storageBucket: "racephotos-wedgedev.appspot.com",
            messagingSenderId: "1019867288874",
            appId: "1:1019867288874:web:370ba3b712aafc45a13a53",
            measurementId: "G-L106GX989N"
        });
    }
    
    firebaseApp.analytics();
    return firebaseApp;
};

export default Firebase;
export const getPerformance = () => {
    if(!performance) {
        performance = Firebase().performance();
    }
    return performance;
}
export const getAnalytics = () => {
    if(analytics) {
        return analytics;
    }
    analytics = Firebase().analytics();
    return analytics;
} 

export type Reference = firebase.storage.Reference
export type StorageError  = firebase.storage.FirebaseStorageError
export type Firestore = firebase.firestore.Firestore
export type Auth = typeof firebase.auth.Auth