import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Landing from './pages/landing';
import InfinitePhotoView from './pages/photo-view';
import Admin from './pages/admin/index';
import './App.css';

function App() {
  
  
  return (
    <Container className="h-100 wedge_justifyContentCenter">
      <div>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/view/:customerId" component={InfinitePhotoView} />
            <Route exact path="/admin" component={Admin} />
          </Switch>
        </BrowserRouter>
      </div>
    </Container>
  );
}

export default App;
