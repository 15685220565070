import React, { useState, useEffect, Suspense } from 'react';
import '../App.css';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import ICustomer from '../interfaces/ICustomer';
import Firebase, { getAnalytics, getPerformance } from '../utils/Firebase';
import IProject from '../interfaces/IProject';
import CustomSpinner from '../utils/components/Spinner';
import IReferenceMeta from '../interfaces/IReferenceMeta';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faDownload, faTimes } from '@fortawesome/free-solid-svg-icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import placeholder from '../assets/images/placeholder.png';

const Component = (props: any) => {
    const [customer, loadCustomer] = useState<ICustomerState>({ status: 0, data: {} as ICustomer });
    const [project, loadProject] = useState<IProjectState>({ status: 0, data: {} as IProject });
    const [listItems, setListItems] = useState<IImage[]>([]);
    const [isFetching, setIsFetching] = useState(false);
    const [page, setPage] = useState<string | null>(null);
    const [modal, setModal] = useState<IModalState>({ active: false, url: '', fullSizeDownload: '' });
    const history = useHistory();
    const params = useParams();

    const fireApp = Firebase();
    const fireStore = fireApp.firestore();
    const fireStorage = fireApp.storage();
    const analytics = getAnalytics();
    const performance = getPerformance();

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        fetchData();
    }, [project.data.id]);

    const init = async () => {
        let loadedCustomer = props.location?.state?.customer;
        const customerId = props.match.params.customerId;
        analytics.setAnalyticsCollectionEnabled(true);
        if (!loadedCustomer) {
            const data = await fireStore.collection('customers').doc(customerId).get();
            if (data.exists) {
                loadedCustomer = data.data();
            }
        }
        if (loadedCustomer) {
            loadCustomer({ status: 1, data: loadedCustomer });
        }
    };

    const fetchData = async () => {
        console.debug((!isFetching && customer.status > 0 && project.status > 0));

        if (!isFetching && customer.status > 0 && project.status > 0 && page !== "empty") {
            const trace = performance.trace("fetchPhotos");
            trace.start();
            console.debug('...');
            setIsFetching(true);
            const refUrl = `race-photos/${customer.data.id}/${project.data.id}/resized/`;
            const fullSizeUrl = `race-photos/${customer.data.id}/${project.data.id}`;
            console.debug(refUrl);

            const list = page && page.length > 2 ?
                await fireStorage.ref(refUrl).list({ pageToken: page, maxResults: 21 }) :
                await fireStorage.ref(refUrl).list({ maxResults: 21 });


            if (list.items.length < 21) {
                setPage("empty");
            } else {
                setPage(list.nextPageToken);
            }
            console.debug(list.items.length, list.nextPageToken);
            const updatedItems = (await Promise.all(list.items.map(async item => {
                const [url, meta] = await Promise.all([
                    item.getDownloadURL(),
                    item.getMetadata()
                ]) as [string, IReferenceMeta];
                const fullSizeDownload: string | null = await fireStorage.ref(`${fullSizeUrl}/${meta.name.replace(/_([0-9])+x([0-9])+./g, '.')}`).getDownloadURL().catch((e) => { console.error(e); return null; });
                if (fullSizeDownload) {
                    return {
                        url,
                        fullSizeDownload
                    };
                }
                return null;
            }))).filter(val => val != null) as IImage[];
            console.debug(updatedItems);

            setListItems([...listItems, ...updatedItems]);
            setIsFetching(false);
            trace.stop();
            return [...listItems, ...updatedItems];
        }

        return listItems;
    };

    const fetchMoreListItems = () => {
        fetchData();
    };

    const triggerImageModal = (image: IImage) => {
        analytics.logEvent("select_item", { item_list_id: image.url });
        setModal({ ...modal, active: true, ...image });
    };

    const printItems = () => {
        let counter = 0;
        let currentIndex = 0;
        return listItems.reduce((reduced: JSX.Element[][], listItem: IImage, i: Number) => {
            const output = (
                <div className="col-sm-6 col-md-4 my-2" key={`image_${new Date().getTime()}_${i}`} style={{ "cursor": "pointer" }}>
                    <Card onClick={() => { triggerImageModal(listItem); }}>
                        <Card.Body>
                            <Suspense fallback={<img src={placeholder} alt="Loading..." width="500" height="500" />}>
                                <img src={listItem.url} alt={`Race - ${i}`} className='img-fluid' />
                            </Suspense>
                        </Card.Body>
                    </Card>
                </div>);
            if (i === 0) {
                //first item
                reduced.push([output]);
                counter = 1;
            } else if (counter < 3) {
                reduced[currentIndex].push(output);
                counter++;
            } else {
                reduced.push([output]);
                counter = 1;
                currentIndex++;
            }
            return reduced;
        }, []).map((row, i) => {
            return <Row className="wedge_justifyContentCenter" key={`row_${i}`}>{row}</Row>;
        });
    };
    const closeModal = () => {
        setModal({ ...modal, active: false });
    };

    const triggerLoadProject = (project: IProject) => {
        analytics.setCurrentScreen("photo-view", { ...project, global: true });
        analytics.logEvent("page_view");
        loadProject({ status: 1, data: project });
        setListItems([]);
        setPage(null);
    };

    switch (customer.status) {
        case 1: {
            switch (project.status) {
                case 1: {
                    return <div className="h-100" style={{
                        "position": "absolute",
                        "top": "8%",
                        "left": "1%",
                        // "overflowY": "scroll",
                        "width": "97%"
                    }}>
                        <div className="w-100 text-center">
                            <h4>Gallery : {project.data.name}</h4>
                            <p>Select the photo to view it in full size or download.</p>
                            {
                                customer.data.projectList.map((proj, i) => {
                                    if (proj.id !== project.data.id) {
                                        return (
                                            <Button
                                                variant="outline-info"
                                                key={proj.id}
                                                onClick={() => { triggerLoadProject(proj); }}>
                                                View {proj.name} <FontAwesomeIcon icon={faArrowRight} />
                                            </Button>
                                        );
                                    }
                                    return <React.Fragment></React.Fragment>;
                                })
                            }
                        </div>
                        <div className="h-100">
                            <InfiniteScroll
                                dataLength={listItems.length} //This is important field to render the next data
                                next={() => { return fetchMoreListItems(); }}
                                hasMore={page !== "empty"}
                                className="w-100"
                                style={{ "overflowX": "hidden" }}
                                loader={(<div className="w-100 text-center" style={{ "height": "100px" }}><CustomSpinner size="small" /></div>)}
                                endMessage={<React.Fragment></React.Fragment>}
                                // below props only if you need pull down functionality
                                refreshFunction={() => { fetchMoreListItems(); }}
                                pullDownToRefresh
                                pullDownToRefreshThreshold={20}
                                pullDownToRefreshContent={
                                    <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                                }
                                releaseToRefreshContent={
                                    <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                                }
                            >
                                {printItems()}
                            </InfiniteScroll>
                        </div>
                        <Modal show={modal.active} onHide={() => { closeModal(); }} size="xl" centered>
                            <Modal.Header>
                                Download the full size image
                                <Button onClick={() => { closeModal(); }} variant="link">
                                    <FontAwesomeIcon icon={faTimes} size="2x" />
                                </Button>
                            </Modal.Header>
                            <Modal.Body><img src={modal.url} alt="Full Size Sample" className="w-100 h-100" /></Modal.Body>
                            <Modal.Footer>
                                <p>To view the full size image, click the download button here</p>
                                <a onClick={() => { analytics.logEvent("select_item", { item_list_id: modal.fullSizeDownload }); }}
                                    href={modal.fullSizeDownload}
                                    target="_BLANK"
                                    rel="noreferrer"
                                    download className="pr-3">
                                    <FontAwesomeIcon icon={faDownload} size="2x" />
                                </a>
                            </Modal.Footer>
                        </Modal>
                    </div>;
                }
                default: {

                    return (
                        <React.Fragment>
                            <Row>
                                {
                                    customer.data.projectList.map((project, i) => {
                                        return (
                                            <Col key={project.id}>
                                                <Card>
                                                    <Card.Img variant="top" src={project.logoUrl} />
                                                    <Card.Header>{project.name}</Card.Header>
                                                    <Card.Body>{project.description}</Card.Body>
                                                    <Card.Footer>
                                                        <Button onClick={() => {
                                                            triggerLoadProject(project);
                                                        }}>View Photos <FontAwesomeIcon icon={faArrowRight} /> </Button>
                                                    </Card.Footer>
                                                </Card>
                                            </Col>
                                        );
                                    })
                                }
                            </Row>
                        </React.Fragment>
                    );
                }
            }
        }
        default: {
            return <CustomSpinner />;
        }
    }
};

export default withRouter(Component);

interface ICustomerState {
    status: 0 | 1 | 2,
    data: ICustomer;
};

interface IProjectState {
    status: 0 | 1 | 2,
    data: IProject;
};

interface IModalState extends IImage {
    active: Boolean;
}

interface IImage {
    url: string,
    fullSizeDownload: string;
}
