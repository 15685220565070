/**
 * Still to do
 * dynamic useEffect?
 * show all files (not just one)
 */
import React, { useState, useEffect, useRef } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import FormFile from 'react-bootstrap/FormFile';
import Button from 'react-bootstrap/Button';
import CustomSpinner from '../../utils/components/Spinner';
import ImageUpload from '../../utils/components/ImageUpload';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import ICustomer from '../../interfaces/ICustomer';
import Firebase from '../../utils/Firebase';
import FirebaseUi, { reauthUser } from '../../utils/FirebaseAuth';
import CustomFileInput from 'bs-custom-file-input';
import IProject from '../../interfaces/IProject';


const Login = () => {
    const [authUser, setAuthUser] = useState<any>({ status: 0, data: {} });
    const [customer, loadCustomer] = useState<ICustomerState>({ status: 0, data: {} as ICustomer });
    const [project, loadProject] = useState<IProjectState>({ status: 0, data: {} as IProject });
    const [imageArray, setImages] = useState<JSX.Element[]>([]);

    const [projectPickerForm, loadProjectPickerForm] = useState<any>({});

    const fireApp = Firebase();
    const storage = fireApp.storage();
    const fireStore = fireApp.firestore();
    const fileRef = useRef<HTMLInputElement | null>(null);

    const fetchUserCustomers = (user: any) => {
        if (user.linkedCustomers && user.linkedCustomers.length > 0) {
            console.log('linked customers found');
            //TODO: handle multiple customers
            fireStore.collection('customers').doc(user.linkedCustomers[0]).get().then(customer => {
                if (customer.data()) {
                    loadCustomer({ status: 1, data: customer.data() as ICustomer });
                    localStorage.setItem('customer', JSON.stringify(customer.data()));
                }
            });
        }
    };
    useEffect(() => {
        const cachedUser = localStorage.getItem('user');
        const cachedAuth = localStorage.getItem('auth');
        const cachedCustomer = localStorage.getItem('customer');
        if (cachedUser && cachedAuth) {
            console.log('user already logged in');
            const parsedUser = JSON.parse(cachedUser);
            const parsedAuth = JSON.parse(cachedAuth);
            reauthUser(fireApp.auth(), parsedAuth);

            setAuthUser({ status: 1, data: parsedUser, authResult: JSON.parse(cachedAuth) });
            if (cachedCustomer) {
                loadCustomer({ status: 1, data: JSON.parse(cachedCustomer) });
            } else {
                fetchUserCustomers(parsedUser);
            }
        } else {
            FirebaseUi(fireApp.auth(), {
                authResult: (authedUser) => {
                    console.log(authedUser);
                    if (authedUser.additionalUserInfo && authedUser.additionalUserInfo.isNewUser) {
                        console.log('new user');
                        //TODO: load the new user modal stuff here
                    }
                    fireStore.collection('user').doc(authedUser.user.uid).get().then(doc => {
                        const data = doc.data();
                        if (data) {
                            setAuthUser({ status: 1, data, authResult: authedUser });
                            localStorage.setItem('auth', JSON.stringify(authedUser));
                            localStorage.setItem('user', JSON.stringify(data));
                            fetchUserCustomers(data);
                        } else {
                            setAuthUser({ status: 0 });
                        }
                    });

                    return false;
                }
            });
        }
        CustomFileInput.init();
    }, []);
    
    const saveFiles = async () => {
        if (fileRef.current && fileRef.current.files) {
            //scope here to add a sub-project (ie day 1, day 2)
            const refString = `race-photos/${customer.data.id}/${project.data.id}/`;
            const ref = storage.ref(refString);

            const images = Object.keys(fileRef.current.files).map((key,i) => {
                //@ts-ignore
                const file = fileRef.current.files[key] as File;

                // no we get the current time and add the index so everything is sorted by upload time
                const now = Date.now();

                return <ImageUpload
                        customerId={customer.data.id}
                        file={file}
                        firestore = {fireStore}
                        projectId = {project.data.id}
                        refString = {refString}
                        storageRef = {ref}
                        key={key}
                        idPrefix ={`${now}_${i}`}
                />
            });

            setImages(images);

        }
    };

    switch (authUser.status) {
        case 1: {
            if (customer.status !== 1) {
                return <CustomSpinner />;
            }
            //customer loaded...
            switch (project.status) {
                case 1: {
                    return (
                        <React.Fragment>
                            <Form >
                                <p>Please add the photos upload</p>
                                <FormFile
                                    multiple={true}
                                    accept="image/*"
                                    ref={fileRef}
                                    custom={true}
                                    label="Select Image Files to upload"
                                    onChange={() => { saveFiles(); }}
                                ></FormFile>
                            </Form>
                            <br/>
                            <br/>
                            <hr />
                            {imageArray}

                        </React.Fragment>
                    );
                }
                default: {
                    return (
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            let projectData = {} as IProject;
                            if (projectPickerForm && projectPickerForm.projectSelector != null) {
                                projectData = customer.data.projectList[projectPickerForm.projectSelector];
                            }
                            loadProject({ status: 1, data: projectData });
                        }}>
                            <p>Enter the details for the new project or select the project to use</p>
                            <Form.Group controlId="projectSelector">
                                <Form.Label>Select Project</Form.Label>
                                <Form.Control as="select" name="projectSelector" onChange={(event) => {
                                    loadProjectPickerForm({ ...projectPickerForm, 'projectSelector': event.currentTarget.value });
                                    console.log(event, event.currentTarget);
                                    console.log(event.currentTarget.value);
                                }}>
                                    <option selected>No Project selected</option>
                                    {
                                        customer.data.projectList.map((option, i) => {
                                            return <option key={`${option.id}`} value={i}>{option.name}</option>;
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                            <Button className="mt-3" type="submit">Next</Button>
                        </Form>
                    );
                }
            }
        }
        default: {
            return (
                <React.Fragment>
                    <link type="text/css" rel="stylesheet" href="https://www.gstatic.com/firebasejs/ui/4.8.0/firebase-ui-auth.css" />
                    <div id="firebaseui-auth-container"></div>
                </React.Fragment>
            );
        }
    }
};

export default withRouter(Login);

interface ICustomerState {
    status: 0 | 1 | 2,
    data: ICustomer;
}
interface IProjectState {
    status: 0 | 1 | 2,
    data: IProject;
}
