import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/esm/Container';
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Login from './login';

function App() {


  return (
    <Switch>
      <Route exact path="/admin/" component={Login} />
    </Switch>
  );
}

export default App;
